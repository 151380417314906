import React from 'react';
import styles from './RestaurantOrderDetailsStyles';
import TitleA from '../titleA/TitleA';
import {Text, View} from 'react-native';
import TrashSVG from '../svgs/TrashSVG';
import TouchableItem from '../TouchableItem';
import MinusSVG2 from '../svgs/MinusSVG2';
import PlusSVG2 from '../svgs/PlusSVG2';
import Colors from '../../theme/colors';

const RestaurantCart = ({product, addProduct, removeProductByID}) => {
  const groupedComposition = product?.composition?.reduce((grouped, item) => {
    if (!grouped[item.category]) {
      grouped[item.category] = [];
    }
    grouped[item.category].push(item);
    return grouped;
  }, {});

  const remove = (item) => removeProductByID({id: item.id});
  // const [quantity, setQuantity] = useState(1);

  const handlePlus = (item) => {
    addProduct({id: item.id, quantity: 1, price: item.price});
  };
  const handleMinus = (item) => {
    addProduct({id: item.id, quantity: -1, price: item.price});
  };
  return (
    <>
      <View style={styles.title}>
        <TitleA>{product?.quantity}</TitleA>
        <TitleA style={{flexBasis: '70%'}}>{product?.name}</TitleA>
        <TitleA>{product?.price}€</TitleA>
      </View>
      {Object.keys(groupedComposition).length > 0 && (
        <View style={styles.subDetailsContainer}>
          {Object.keys(groupedComposition).map((category) => {
            const items = groupedComposition[category];
            if (items && items.length > 0) {
              return (
                <>
                  <Text style={{color: Colors.darkGray}}>{category}</Text>
                  {items.map((composition) => (
                    <View style={styles.row}>
                      <Text
                        style={{
                          color: Colors.normalGray,
                          fontFamily: 'Light',
                          paddingRight: 15,
                        }}>
                        1
                      </Text>
                      <Text
                        style={{
                          color: Colors.normalGray,
                          fontFamily: 'Light',
                        }}>
                        {composition.name}
                      </Text>
                    </View>
                  ))}
                </>
              );
            }
          })}
        </View>
      )}
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingLeft: 24,
          paddingVertical: 8,
        }}>
        <TouchableItem onPress={() => remove(product)} style={styles.deleteBtn}>
          <TrashSVG
            style={{
              width: 17,
              height: 17,
              marginTop: 2,
              marginRight: 4,
            }}
          />
          <Text style={{color: Colors.red}}>Delete</Text>
        </TouchableItem>
        <View>
          <View style={styles.quantityHandler}>
            {product.quantity > 0 && (
              <TouchableItem
                onPress={() => handleMinus(product)}
                style={{marginRight: 10}}>
                <MinusSVG2
                  stroke={Colors.normalGray}
                  fill={Colors.normalGray}
                />
              </TouchableItem>
            )}

            {product.quantity > 0 && (
              <Text
                style={{
                  color: Colors.normalGray,
                  fontFamily: 'SemiBold',
                }}>
                {product.quantity}
              </Text>
            )}
            <TouchableItem
              style={{marginLeft: 10}}
              onPress={() => handlePlus(product)}>
              <PlusSVG2 />
            </TouchableItem>
          </View>
        </View>
      </View>
      {/* <View style={styles.separator} /> */}
    </>
  );
};

export default RestaurantCart;
