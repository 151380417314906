import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {persistStore, persistCombineReducers} from 'redux-persist';

const storePersistConfig = {
  key: 'store',
  storage: AsyncStorage,
  //whitelist: ['cart', 'store']
};

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  whitelist: ['cart', 'store', 'events', 'prefs'],
};

const cartPersistConfig = {
  key: 'cart',
  storage: AsyncStorage,
  //whitelist: ['cart', 'store']
};

const INITIAL_STATE = {
  products: [],
  accessRestriction: null, // Will store { restriction, verifiedEmail }
};

const INITIAL_EVENT_STATE = {};
const INITIAL_REFERRAL_STATE = {
  referralID: '',
};

const prefsReducer = (
  state = {pickMode: 'inhouse', tierMode: 'food'},
  action,
) => {
  switch (action.type) {
    case 'SET_PICKMODE':
      const pickState = {...state, pickMode: action.payload};
      return pickState;
    case 'SET_TIERMODE':
      const tierState = {...state, tierMode: action.payload};
      return tierState;
    case 'SET_LASTID':
      const lastidState = {...state, lastId: action.payload};
      return lastidState;
    default:
      return state;
  }
};

const storeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_STORE':
      return {...action.payload};
    default:
      return state;
  }
};

const referralIDReducer = (state = INITIAL_REFERRAL_STATE, action) => {
  switch (action.type) {
    case 'SET_REFERRAL':
      const newState = {
        referralID: action.payload,
      };
      return newState;
    default:
      return state;
  }
};

const eventReducer = (state = INITIAL_EVENT_STATE, action) => {
  switch (action.type) {
    case 'SET_EVENT':
      const newState = {
        id: action.payload.id,
        coverImage: action.payload.coverImage,
        title: action.payload.title,
        haspromocode: action.payload.haspromocode,
        address: action.payload.address,
        startDate: action.payload.startDate,
        organisation: action.payload.organisation,
        tickets: action.payload.tickets,
        tickets: action.payload.tickets,
        participantInfo: action.payload.participantInfo ?? [],
        participantInfo: action.payload.participantInfo ?? [],
      };
      return newState;
    default:
      return state;
  }
};
const commandeGroupeReducer = (state = {}, action) => {
  switch (action.type) {
    case 'ADD_COMMANDE_GROUPE':
      return action.payload;
    case 'REMOVE_COMMANDE_GROUPE':
      return {};
    default:
      return state;
  }
};

const cartReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_ACCESS_RESTRICTION': {
      // Get current products
      const { products } = state;
      
      // Filter out products with different access restrictions
      const filteredProducts = products.filter(product => {
        // Keep products without any restrictions
        if (!product.accessRestrictions || product.accessRestrictions.length === 0) {
          return true;
        }
        
        // Remove products with restrictions different from the new one
        return product.accessRestrictions.some(
          restriction => restriction.id === action.payload.restriction.id
        );
      });

      // Return new state with filtered products and new restriction
      return {
        ...state,
        products: filteredProducts,
        accessRestriction: action.payload
      };
    }

    case 'CLEAR_MISMATCHED_PRODUCTS': {
      const newRestriction = action.payload;
      if (!newRestriction) return state;
    
      // Keep only products with matching access restriction
      const filteredProducts = state.products?.filter(product => {
        const productRestriction = product.accessRestriction;
        console.log("product", product);
        console.log("newRestriction", newRestriction);
        console.log("productRestriction", productRestriction);
        return !productRestriction || productRestriction.verifiedEmail === newRestriction.verifiedEmail;
      }) || [];
      console.log(filteredProducts);
    
      return {
        ...state,
        products: filteredProducts
      };
    }
    case 'CLEANUP_CART_AND_RESTRICTIONS': {
      // Clean cart and remove access restrictions when:
      // 1. User switches to event with different/no restrictions
      // 2. User closes modal without selecting restriction
      return {
        ...state,
        products: [],
        accessRestriction: null
      };
    }
    case 'CLEAR_ACCESS_RESTRICTION': {
      // Get current products
      const { products } = state;
      
      // Remove all products with any access restrictions
      const filteredProducts = products.filter(product => 
        !product.accessRestrictions || product.accessRestrictions.length === 0
      );

      return {
        ...state,
        products: filteredProducts,
        accessRestriction: null
      };
    }
    case 'REMOVE_PRODUCT_BY_ID':
      const newStateData = state.products.filter(
        (obj) => obj.id !== action.payload.id,
      );
      return {...state, products: newStateData};
      case 'ADD_PRODUCT': {
        // Destructure current state
        const { products, accessRestriction } = state;
        const newProduct = action.payload;
        console.log('newProduct', newProduct);
  
        /**
         * Step 1: Validate Access Restrictions
         */
        // Check if the new product has access restrictions
        if (newProduct.accessRestrictions?.length > 0) {
          // If product has restrictions but no access restriction is verified, reject
          if (!accessRestriction) {
            console.log('Cannot add restricted product without verified access');
            return state;
          }
          
          // Check if the product's restrictions match current verified access
          const hasMatchingRestriction = newProduct.accessRestrictions.some(
            restriction => restriction.id === accessRestriction.restriction.id
          );
          
          // If no matching restriction found, reject
          if (!hasMatchingRestriction) {
            console.log('Product restrictions do not match current access');
            return state;
          }
        }
  
        /**
         * Step 2: Check Existing Product
         */
        // Find if product already exists in cart
        const existingProductIndex = products?.findIndex(
          (product) => product.id === newProduct.id
        );
        
        // Create a new array to avoid mutating state directly
        let updatedProducts = [...products];
  
        /**
         * Step 3: Price Calculations
         */
      const calculatePrices = (product) => {
        // Base product object with access restriction info
        const baseProduct = {
          ...product,
          // Add access restriction info if product has restrictions
          accessRestriction:
            product.accessRestrictions?.length > 0
              ? {
                  restriction: accessRestriction?.restriction,
                  verifiedEmail: accessRestriction?.verifiedEmail,
                }
              : null,
        };

        // If no price deformation, calculate basic total
        if (!product.priceDeformation) {
          const basicTotal = product.includePlatformFees
            ? product.quantity * product.finalPrice
            : product.quantity * product.price;

          return {
            ...baseProduct,
            total: basicTotal,
            finalPrice: product.includePlatformFees
              ? product.finalPrice
              : product.price
          };
        }

        /**
         * Price Deformation Calculations
         */
        const basePrice = product.price;
        const deformation = product.priceDeformation;

        // 1. Calculate deformed base price
        const deformedPrice =
          deformation.deformationType === 'PERCENTAGE'
            ? basePrice * (1 - deformation.deformationValue / 100)
            : basePrice - deformation.deformationValue;

        // 2. Calculate price without TVA if applicable
        const deformedPriceHt = product.tvaRate
          ? deformedPrice / (1 + product.tvaRate / 100)
          : deformedPrice;

        // 3. Calculate platform fee on deformed price
        const deformedPlatformFee =
          product.includePlatformFees && product.platformFee
            ? deformedPrice * (product.platformFee / 100)
            : 0;

        // 4. Calculate final unit price
        const deformedFinalPrice = product.includePlatformFees
          ? deformedPrice + deformedPlatformFee
          : deformedPrice;

        // 5. Calculate total based on quantity
        const total = deformedFinalPrice * product.quantity;

        return {
          ...baseProduct,
          deformedPrice,
          deformedPriceHt,
          deformedPlatformFee,
          deformedFinalPrice,
          total,
          finalPrice: deformedFinalPrice,
          // Keep original values for reference
          originalPrice: basePrice,
          originalTotal: basePrice * product.quantity,
        };
      };

      /**
       * Step 4: Handle Product Addition/Update
       */
      if (existingProductIndex === -1) {
        // Adding new product
        const processedNewProduct = calculatePrices(newProduct);
        updatedProducts.push(processedNewProduct);
      } else {
        // Updating existing product
        const existingProduct = updatedProducts[existingProductIndex];
        let updatedProduct = {
          ...existingProduct,
          quantity: existingProduct.quantity + newProduct.quantity  
          };
        if(existingProduct.type === "seat") {
          updatedProduct.listSeat = newProduct.listSeat
        }

  
          // Recalculate prices with new quantity
          const processedUpdatedProduct = calculatePrices(updatedProduct);
    
          // Remove product if quantity becomes 0 or less
          if (processedUpdatedProduct.quantity <= 0) {
            updatedProducts.splice(existingProductIndex, 1);
          } else {
            updatedProducts[existingProductIndex] = processedUpdatedProduct;
          }
        }
  
        /**
         * Step 5: Return Updated State
         */
        return { 
          ...state,
          products: updatedProducts 
        };
      }
    case 'ADD_PROMOCODE':
      // Pulls current and possible out of previous state
      // We do not want to alter state directly in case
      // another action is altering it at the same time
      //alert('entering here');

      let productsAfterDiscount = state.products;

      if (action.payload) {
        console.log(action.payload);
        productsAfterDiscount.map((product, prdIndex) => {
          let {price} = product;
          const {quantity} = product;

          const discountFound = action.payload.products.some(
            (e) => e == product.id,
          );
          if (discountFound) {
            let priceAfterDiscount = price;
            if (action.payload.mode == 'amount') {
              // priceAfterDiscount = product.includePlatformFees
              //   ? product.price -
              //     action.payload.discount +
              //     (product.price * product.platformFee) / 100
              //   : (priceAfterDiscount = price - action.payload.discount);

              priceAfterDiscount = product.includePlatformFees
                ? parseFloat(
                    (
                      product.priceAfterFeePlatform -
                      action.payload.discount +
                      (product.priceAfterFeePlatform * product.platformFee) /
                        100
                    ).toFixed(2),
                  )
                : price - action.payload.discount;
              //  parseFloat((price - action.payload.discount).toFixed(2));
            } else {
              // priceAfterDiscount = product.includePlatformFees
              //   ? product.price -
              //     product.price * action.payload.discount * 0.01 +
              //     (product.price * product.platformFee) / 100
              //   : product.price -
              //     product.price * action.payload.discount * 0.01;

              const discountRate = action.payload.discount / 100;
              priceAfterDiscount = product.includePlatformFees
                ? parseFloat(
                    (
                      product.priceAfterFeePlatform -
                      product.priceAfterFeePlatform * discountRate
                    ).toFixed(2),
                  )
                : product.price -
                  product.price * action.payload.discount * 0.01;
            }

            productsAfterDiscount[prdIndex].priceAfterDiscount =
              priceAfterDiscount;
            productsAfterDiscount[prdIndex].hasDiscount = true;
            productsAfterDiscount[prdIndex].total =
              quantity * priceAfterDiscount;
          }
        });
      }

      //const afterDiscountState = { products: productsAfterDiscount };
      return {...state, products: [...productsAfterDiscount]};
    //return afterDiscountState;

    // Finally, update the redux state

    case 'REM_PROMOCODE':
      // Pulls current and possible out of previous state
      // We do not want to alter state directly in case
      // another action is altering it at the same time
      //alert('entering here');
      let productsBeforeDiscount = state.products;
      if (action.payload) {
        console.log(action.payload);

        productsBeforeDiscount.map((product, prdIndex) => {
          let {price} = product;
          const {quantity} = product;
          const discountFound = action.payload.products.some(
            (e) => e == product.id,
          );
          if (discountFound) {
            productsBeforeDiscount[prdIndex].hasDiscount = false;
            productsBeforeDiscount[prdIndex].total = quantity * price;
          }
        });
        // Finally, update the redux state
        const beforeDiscountState = {products: productsBeforeDiscount};
      }

      //return beforeDiscountState;
      return {...state, products: [...productsBeforeDiscount]};

    case 'EMPTY_CART':
      // Pull friend out of friends.possible
      // Note that action.payload === friendIndex
      //console.log("emptying cart");

      // Finally, update the redux state
      const emptyState = {products: [], accessRestriction: null};
      //console.log(emptyState.products);

      return emptyState;

    case 'SYNC_CART':
      // Pull friend out of friends.possible
      // Note that action.payload === friendIndex
      console.log('syncing cart');
      let oldproducts = state.products;
      let allnewproducts = action.payload;
      console.log(oldproducts);
      if (action.payload) {
        console.log(action.payload);
        oldproducts = oldproducts.map((item) => {
          const item2 = allnewproducts.find((i2) => i2.id === item.id);
          return item2 ? {...item, ...item2} : item;
        });
        console.log('updated products');
        console.log(oldproducts);
        return {...state, products: [...oldproducts]};
      }

      // Finally, update the redux state
      //const emptyState1 = {products: []};
      //console.log(emptyState.products);

      return state;

    default:
      return state;
  }
};
const persistCombinedReducers = persistCombineReducers(persistConfig, {
  cart: cartReducer,
  prefs: prefsReducer,
  store: storeReducer,
  event: eventReducer,
  referralID: referralIDReducer,
  commandeGroupe: commandeGroupeReducer,
});

/*export default combineReducers({
  cart: cartReducer,
  store: storeReducer
});*/
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  persistCombinedReducers,
  composeEnhancer(applyMiddleware(thunk)),
);

export const persistor = persistStore(store);
