const config = {
  /*
    theme: 'jade' | 'redOrange' | 'blueberry'
   */
  local: {
    curtheme: 'jade',
    links: {
      webappurl: 'http://localhost:19006/',
      //webappurl: "https://pwauat.web.app/"
    },
    api: {
      customers: {
        create:
          'https://europe-west1-instadrinkuat.cloudfunctions.net/app/stripe/customers/create',
      },
      cards: {
        get: 'https://europe-west1-instadrinkuat.cloudfunctions.net/app/stripe/customers/getcards',
        getdefault:
          'https://europe-west1-instadrinkuat.cloudfunctions.net/app/stripe/customers/getdefaultcard',
        retrieve:
          'https://europe-west1-instadrinkuat.cloudfunctions.net/app/stripe/cards/getcard',
        create:
          'https://europe-west1-instadrinkuat.cloudfunctions.net/app/stripe/customers/createcard',
      },
      pms: {
        get: 'https://europe-west1-instadrinkuat.cloudfunctions.net/app/stripe/customers/getpms',
      },
      receipt: {
        get: 'https://europe-west1-instadrinkuat.cloudfunctions.net/app/stripe/receipt',
      },
      charges: {
        create:
          'https://europe-west1-instadrinkuat.cloudfunctions.net/app/api/payments/stripe/create',
      },
      checkout: {
        create:
          'https://europe-west1-instadrinkuat.cloudfunctions.net/app/web/stripe/checkout/sessions/create',
      },
      orders: {
        precreate:
          'https://europe-west1-instadrinkuat.cloudfunctions.net/app/api/orders/precreate',
      },
      reservations: {
        precreate:
          'https://europe-west1-instadrinkuat.cloudfunctions.net/app/api/reservations/precreate',
      },
    },
    paiement: {
      publishableKey:
        'pk_test_51Iss0qCTdpEbBR7uJaxn7WWXmfWVqliIjTE8zehaxGH0SCI2Q2AkpCGOOrLTjbzwfbHCVLrCxRo5xyuFALXd0MBm00Pfkab9Nj',
    },
  },
  uat: {
    curtheme: 'jade',
    links: {
      //webappurl: 'http://localhost:19006/',
      webappurl: 'https://pwauat.web.app/',
    },
    api: {
      customers: {
        create:
          'https://europe-west1-instadrink-47f0f.cloudfunctions.net/app/stripe/customers/create',
      },
      cards: {
        get: 'https://europe-west1-instadrink-47f0f.cloudfunctions.net/app/stripe/customers/getcards',
        getdefault:
          'https://europe-west1-instadrink-47f0f.cloudfunctions.net/app/stripe/customers/getdefaultcard',
        retrieve:
          'https://europe-west1-instadrink-47f0f.cloudfunctions.net/app/stripe/cards/getcard',
        create:
          'https://europe-west1-instadrink-47f0f.cloudfunctions.net/app/stripe/customers/createcard',
      },
      pms: {
        get: 'https://europe-west1-instadrink-47f0f.cloudfunctions.net/app/stripe/customers/getpms',
      },
      charges: {
        create:
          'https://europe-west1-instadrink-47f0f.cloudfunctions.net/app/api/payments/stripe/create',
      },
      checkout: {
        create:
          'https://europe-west1-instadrink-47f0f.cloudfunctions.net/app/web/stripe/checkout/sessions/create',
      },
      receipt: {
        get: 'https://europe-west1-instadrink-47f0f.cloudfunctions.net/app/stripe/receipt',
      },
      orders: {
        precreate:
          'https://europe-west1-instadrink-47f0f.cloudfunctions.net/app/api/orders/precreate',
      },
      reservations: {
        precreate:
          'https://europe-west1-instadrink-47f0f.cloudfunctions.net/app/api/reservations/precreate',
      },
    },
    paiement: {
      publishableKey:
        'pk_test_51Iss0qCTdpEbBR7uJaxn7WWXmfWVqliIjTE8zehaxGH0SCI2Q2AkpCGOOrLTjbzwfbHCVLrCxRo5xyuFALXd0MBm00Pfkab9Nj',
    },
  },
  hom: {
    curtheme: 'jade',
    links: {
      webappurl: 'http://localhost:19006/',
      //webappurl: "https://pwauat.web.app/"
    },
    api: {
      customers: {
        create:
          'https://europe-west1-instadrinkuat.cloudfunctions.net/app/stripe/customers/create',
      },
      cards: {
        get: 'https://europe-west1-instadrinkuat.cloudfunctions.net/app/stripe/customers/getcards',
        getdefault:
          'https://europe-west1-instadrinkuat.cloudfunctions.net/app/stripe/customers/getdefaultcard',
        retrieve:
          'https://europe-west1-instadrinkuat.cloudfunctions.net/app/stripe/cards/getcard',
        create:
          'https://europe-west1-instadrinkuat.cloudfunctions.net/app/stripe/customers/createcard',
      },
      pms: {
        get: 'https://europe-west1-instadrinkuat.cloudfunctions.net/app/stripe/customers/getpms',
      },
      receipt: {
        get: 'https://europe-west1-instadrinkuat.cloudfunctions.net/app/stripe/receipt',
      },
      charges: {
        create:
          'https://europe-west1-instadrinkuat.cloudfunctions.net/app/api/payments/stripe/create',
      },
      checkout: {
        create:
          'https://europe-west1-instadrinkuat.cloudfunctions.net/app/web/stripe/checkout/sessions/create',
      },
      orders: {
        precreate:
          'https://europe-west1-instadrinkuat.cloudfunctions.net/app/api/orders/precreate',
      },
      reservations: {
        precreate:
          'https://europe-west1-instadrinkuat.cloudfunctions.net/app/api/reservations/precreate',
      },
    },
    paiement: {
      publishableKey:
        'pk_test_51Iss0qCTdpEbBR7uJaxn7WWXmfWVqliIjTE8zehaxGH0SCI2Q2AkpCGOOrLTjbzwfbHCVLrCxRo5xyuFALXd0MBm00Pfkab9Nj',
    },
  },
  prod: {
    curtheme: 'jade',
    links: {
      //webappurl: 'http://localhost:19006/',
      webappurl: 'https://web.instadrink.io/',
    },
    api: {
      customers: {
        create:
          'https://europe-west1-instadrink-eecf1.cloudfunctions.net/app/stripe/customers/create',
      },
      cards: {
        get: 'https://europe-west1-instadrink-eecf1.cloudfunctions.net/app/stripe/customers/getcards',
        getdefault:
          'https://europe-west1-instadrink-eecf1.cloudfunctions.net/app/stripe/customers/getdefaultcard',
        retrieve:
          'https://europe-west1-instadrink-eecf1.cloudfunctions.net/app/stripe/cards/getcard',
        create:
          'https://europe-west1-instadrink-eecf1.cloudfunctions.net/app/stripe/customers/createcard',
      },
      pms: {
        get: 'https://europe-west1-instadrink-eecf1.cloudfunctions.net/app/stripe/customers/getpms',
      },
      charges: {
        create:
          'https://europe-west1-instadrink-eecf1.cloudfunctions.net/app/api/payments/stripe/create',
      },
      checkout: {
        create:
          'https://europe-west1-instadrink-eecf1.cloudfunctions.net/app/web/stripe/checkout/sessions/create',
      },
      receipt: {
        get: 'https://europe-west1-instadrink-eecf1.cloudfunctions.net/app/stripe/receipt',
      },
      orders: {
        precreate:
          'https://europe-west1-instadrink-eecf1.cloudfunctions.net/app/api/orders/precreate',
      },
      reservations: {
        precreate:
          'https://europe-west1-instadrink-eecf1.cloudfunctions.net/app/api/reservations/precreate',
      },
    },
    paiement: {
      publishableKey:
        'pk_live_51Iss0qCTdpEbBR7uyPtzbm4EAwGhlvLC6lv7xrh5HdEwnrkl0eLjcCwFUSkdM48Fz6A04gVyouqoDbyNykvK5ziW00XKEjYYP9',
    },
  },
};

const env = 'prod'; //Constants.manifest.releaseChannel
const getEnvVars = () => {
  // if (__DEV__) {
  //   return config.hom;
  // } else if (env === 'prod') {
  //   return config.uat;
  // } else if (env === 'hom') {
  //   return config.hom;
  // } else {
  //   return config.prod;
  // }
  return config.prod;
  
};

export default getEnvVars;
