SeatsChart// SeatsChart.js
import React, { useEffect, useRef, useState } from 'react';
import { View, Platform, Text } from 'react-native';

const SEATSIO_REGIONS = {
  EUROPE: 'https://cdn-eu.seatsio.net/chart.js',
  NORTH_AMERICA: 'https://cdn-na.seatsio.net/chart.js',
  SOUTH_AMERICA: 'https://cdn-sa.seatsio.net/chart.js',
  OCEANIA: 'https://cdn-oc.seatsio.net/chart.js'
};
const SEATSIO_SCRIPT_URL = 'https://cdn-eu.seatsio.net/chart.js';
const SeatsChart = ({
  workspaceKey, 
  eventKey,
  onChartRendered,
  onChartError,
  onSeatSelected,
  onSeatDeselected,
  height = 600
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  
  useEffect(() => {
    if (Platform.OS !== 'web') return;

    let chartInstance = null;

    const initializeChart = () => {
      try {
        if (!window.seatsio) {
          throw new Error('seats.io not loaded');
        }

        const config = {
          divId: 'seatsio-container',
          workspaceKey,
          event: eventKey,
          showFullScreenButton: false,
          pricing: [
            {'category': 1, 'price': 30},
            {'category': 2, 'price': 40},
            {'category': 3, 'price': 50}
          ],
          priceFormatter: function(price) {
            return '€' + price;
          },
          onObjectSelected: (obj) => {
            console.log('Seat selected:', obj);
            onSeatSelected?.(obj);
          },
          onObjectDeselected: (obj) => {
            console.log('Seat deselected:', obj);
            onSeatDeselected?.(obj);
          },
          onChartRendered: function() {
            console.log('Chart rendered');
            setIsLoading(false);
            onChartRendered?.();
          },
          onChartRenderingFailed: function(err) {
            console.error('Rendering failed:', err);
            setError(err.message || 'Rendering failed');
            onChartError?.(err);
          },
          showLegend: true,
          showMinimap: true,
          showSectionPricing: true,
          showActiveSectionTooltip: true,
          language: 'fr' 
        };

        console.log('Creating chart with config:', {
          ...config,
          workspaceKey: config.workspaceKey.substring(0, 8) + '...'
        });
        
        chartInstance = new window.seatsio.SeatingChart(config);
        chartInstance.render();

      } catch (err) {
        console.error('Chart initialization error:', err);
        setError(err.message);
        setIsLoading(false);
      }
    };

    // Clean up any existing script
    const existingScript = document.querySelector('script[src*="seatsio"]');
    if (existingScript) {
      existingScript.remove();
    }

    // Load the seats.io script
    const script = document.createElement('script');
    script.src = SEATSIO_SCRIPT_URL;
    script.async = true;
    
    script.onload = function() {
      console.log('seats.io script loaded');
      // Small delay to ensure script is fully initialized
      setTimeout(initializeChart, 100);
    };
    
    script.onerror = function() {
      setError('Failed to load seats.io script');
      setIsLoading(false);
    };
    
    document.head.appendChild(script);

    // Cleanup
    return () => {
      if (chartInstance) {
        try {
          chartInstance.destroy();
        } catch (err) {
          console.error('Error destroying chart:', err);
        }
      }
    };
  }, [workspaceKey, eventKey]);

  if (Platform.OS !== 'web') {
    return null;
  }

  return (
    <View style={{ width: '100%', height }}>
      {isLoading && (
        <View 
          style={{ 
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(255,255,255,0.8)',
            zIndex: 1
          }}
        >
          <Text>Loading seating chart...</Text>
          <Text style={{ fontSize: 12, marginTop: 10, color: '#666' }}>
            WorkspaceKey: {workspaceKey?.substring(0, 8)}...{'\n'}
            EventKey: {eventKey}
          </Text>
        </View>
      )}
      {error && (
        <View 
          style={{ 
            padding: 16,
            backgroundColor: '#ffebee',
            borderRadius: 4
          }}
        >
          <Text style={{ color: '#c62828' }}>Error: {error}</Text>
        </View>
      )}
      <div
        id="seatsio-container"
        style={{
          width: '100%',
          height: '100%',
          visibility: isLoading ? 'hidden' : 'visible'
        }}
      />
    </View>
  );
};

export default SeatsChart;